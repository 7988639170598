$primary-alt: #E03F20;
$dark: #333333;
$darker: #292929;
$darkest: #1C1C1C;
$darkerest: #0f0e0e;
$primary: #F15A24;
$light: #707070;
$white: #FFFFFF;
$subtle-white: rgb(241, 212, 198);
$body-color: $white;
$body-bg: $dark;


$theme-colors: (
  "primary": $primary,
  "primary-alt": $primary-alt,
  "dark": $dark,
  "darker": $darker,
  "darkest": $darkest,
  "white": $white,
  "light": $light
);

$link-color: $white;
$link-hover-color: $primary;
$link-hover-decoration: none;

$navbar-nav-link-padding-x: 1rem;

$input-disabled-bg: $darkerest;
$custom-select-disabled-bg: $darkerest;

$btn-border-width: 0px;
$btn-padding-y: 1rem;
$btn-padding-x: 1.5rem;

$btn-border-radius: 0;

$input-border-width: 0px;
$input-border-focus: disabled;
$input-bg: $darkest;
$input-color: $white;
$input-border-radius: 0;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1056px,
  xl: 1200px,
  xxl: 1420px,
);

$container-max-widths: (
  xs: 540px,
  sm: 541px,
  md: 720px,
  lg: 1020px,
  xl: 1140px,
  xxl: 1380px,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 5,
  8: $spacer * 8,
  9: $spacer * 11
);

$h1-font-size: 50px;
$h2-font-size: $h1-font-size;
$h3-font-size: 40px;
$h4-font-size: 20px;
$font-size-base: 1rem;
$font-weight-base: 300;
$headings-font-weight: 700;

$font-family-base: "Titillium Web", sans-serif;