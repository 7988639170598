html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  text-size-adjust: auto;
}

.btn-primary {
  color: $white !important;
  background: transparent
    linear-gradient(100deg, $primary 0%, $primary-alt 100%) 0% 0% no-repeat
    padding-box;

  &:hover {
    background: transparent
      linear-gradient(0deg, $primary 0%, $primary-alt 100%) 0% 0% no-repeat
      padding-box;
    color: $white !important;
  }

  font-weight: bold;
}

.bg-white {
  color: $dark;

  a {
    color: $dark;

    &:hover {
      color: $primary;
    }
  }
}

.row {
  overflow: hidden;
}

.point-break {
  word-break: break-word;
  font-size: 0;
}

.background-size-cover {
  background-size: cover;
  background-position: 50% 50%;
}

.opacity-35 {
  opacity: 0.35;
}

.skew-from-left {
  position: relative;
}

.skew-from-right {
  position: relative;
  margin-right: -1px;
}

.card-corner {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: $dark transparent transparent transparent;
  }
}

.z-index-top {
  z-index: 1;
}

.img-cover {
  display: block;
  object-fit: cover;
}

.fas {
  vertical-align: middle;
}

.bottom-below {
  border-bottom: 5px solid $primary;
}

ul {
  .fas {
    width: 2rem;
    text-align: center;
    display: inline-block;
  }
}

.navbar {
  border: 0 !important;
  transition-duration: 133.7ms;

  .navbar-nav {
    background: rgba(white, 0);
    transition-duration: 133.7ms;
  }

  .navbar-brand {
    gap: 0.75rem;

    img,
    h3 {
      transition-duration: 133.7ms;
    }

    img {
      height: 55px;
    }
  }
}

@include media-breakpoint-up(lg) {
  html:not([data-scroll="0"]) {
    .navbar {
      padding: 0 !important;
      background: rgba(white, 0) !important;

      .navbar-nav {
        background: white;
        transform: perspective(200px) rotateX(-3deg);
      }

      .navbar-brand {
        svg,
        img {
          height: 37px;
          transition: 133.7ms;
          width: 65px;

          path {
            stroke: black;

            &:not(#orange) {
              fill: white;
            }
          }
        }

        h3 {
          mix-blend-mode: difference;
          margin: 0 !important;
          font-size: 33px;
          transform: perspective(200px) rotateX(-3deg);
          color: white !important;
          -webkit-text-stroke: 1px black;
        }
      }
    }
  }
}

.navbar-toggler {
  @include media-breakpoint-down(lg) {
    img {
      position: absolute;
      left: 1.1rem;
      top: 1.1rem;
      transition-delay: 0.4s;
      transition: opacity 0.1s ease-in-out;
    }

    &[aria-expanded="false"] {
      img {
        opacity: 0;
      }
    }
  }

  .burger-icon {
    width: 2.5rem;
    text-align: center;
    display: inline-block;

    span {
      display: block;
      height: 4px;
      background: $primary;
      border-radius: 1px;

      transition: transform 0.5s, opacity 0.5s ease-in;

      + span {
        margin-top: 6px;
        background: $dark;
      }
    }
  }

  &[aria-expanded="true"] {
    .burger-icon {
      span {
        transform: translate(-10px, 3px) scale(1.2) rotate(-30deg);
        opacity: 0;

        + span {
          transform: translate(6px, -5px) scale(0.5);

          + span {
            transform: translate(9px, -10px) scale(0.3);
          }
        }
      }
    }
  }
}

// // http://jsfiddle.net/C3GPC/2/
// .dynamic-nav {
//     -webkit-transition: all 2s;
//     -moz-transition: all 2s;
//     -o-transition: all 2s;
//     transition: all 2s;
//     padding-top: 1;
// }

@include media-breakpoint-up(lg) {
  .skew-from-right-mobile-dark:after,
  .skew-from-right:after,
  .skew-from-left:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 100%;
    background-color: #333;
    width: 100%;
    height: 200%;
    transform: skew(-20deg);
  }

  .skew-from-right:after {
    left: auto;
    right: 100%;
    top: 0;
    background-color: white;
  }

  .skew-from-right-mobile-dark:after {
    left: auto;
    right: 95%;
    top: 0;
    background-color: #333;
  }

  .code-logo-height {
    height: 250px;
  }
}

.center-image {
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
}

@include media-breakpoint-up(xl) {
  .code-logo-height {
    height: 300px;
  }
}

@include media-breakpoint-down(md) {
  .btn {
    width: 100%;
  }

  .h-mobile-image {
    height: 30vh;
  }

  .skew-from-right-mobile:after,
  .skew-from-right-mobile-dark:after,
  .skew-from-left-mobile:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #333;
    width: 100%;
    height: 200%;
    transform: skew(-80deg);
  }

  .skew-from-right-mobile:after {
    left: 0;
    right: auto;
    top: 0;
    background-color: white;
    transform: skew(80deg);
  }

  .skew-from-right-mobile-dark:after {
    left: 0;
    right: auto;
    top: 0;
    background-color: #333;
    transform: skew(80deg);
  }

  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

.min-height-sm-96px {
  min-height: 96px;
}

@include media-breakpoint-down(sm) {
  .card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

@include media-breakpoint-down(lg) {
  .navbar-toggler {
    img {
      width: 48px;
      height: 28px;
    }

    h3 {
      font-size: 26px;
    }
  }
}

.navbar-nav {
  a:hover {
    color: $primary;

    &:not(.btn)::after {
      @include media-breakpoint-up(lg) {
        content: "";
        width: 100%;
        border-bottom: 5px solid $primary;
        position: absolute;
        bottom: -2.8rem;
        left: 0;
      }
    }
  }
}

strong {
  font-weight: bold;
  color: $primary;
}

.bg-white strong {
  color: $dark;
  text-decoration: none;
}

strong u {
  color: $primary;
  text-decoration: none;
}

.position-bottom {
  position: absolute;
  bottom: 0;
}

.masonry {
  display: flex;
  flex-flow: column wrap;
  max-height: 90vh;
  margin-left: -8px;
  /* Adjustment for the gutter */
  width: 100%;
}

.masonry-brick {
  margin: 0 8px 8px 0;
  /* Some gutter */
  max-width: 33%;
}

.height-300 {
  height: 300px;
}

.custom-input {
  padding: 5rem;
}

.form-control:focus {
  box-shadow: none;
}

.form-control::placeholder {
  font-style: italic;
  color: $light;
}

.unchanged {
  &:focus {
    box-shadow: none;
    outline: none;
  }

  border: none;
  border-radius: 0px;
  font-style: italic;
  color: $light;
  padding: 5px;
}

.changed {
  &:focus {
    box-shadow: none;
    outline: none;
  }

  border: none;
  border-radius: 0px;
  font-style: normal;
  color: $white;
  padding: 5px;
}

.no-appearance {
  appearance: none;
}

.font-style-normal {
  font-style: normal;
}

.scroll-margin-header {
  scroll-margin-top: 128px;
}

.opacity-fading {
  opacity: 0.5;
  animation: fading 1.5s infinite;
}

@keyframes fading {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.client-company-logos {
  @extend .p-lg-6, .text-center, .align-middle, .opacity-35;

  img {
    @extend .mx-4, .my-3, .my-md-5;
  }

  a {
    img {
      transition: transform 0.1337s ease-in-out;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }
}

.honing {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

psylac {
  width: 1rem;
  height: 1rem;
  position: absolute;
  cursor: wait;
  top: 38.2%;
  right: 31.1%;
}

psyl {
  opacity: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  animation: 420ms ease-in-out infinite psylocybin;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 10vw #000;
  z-index: 1;
}

@keyframes psylocybin {
  0% {
    transform: scale(1);
    box-shadow: inset 0 0 10vw #000;
  }

  16% {
    box-shadow: inset 0 0 10vw #ff0;
  }

  33% {
    box-shadow: inset 0 0 10vw #0f0;
  }

  50% {
    transform: scale(1.05);
    box-shadow: inset 0 0 10vw #f00;
  }

  66% {
    box-shadow: inset 0 0 10vw #00f;
  }

  83% {
    box-shadow: inset 0 0 10vw #0ff;
  }

  100% {
    transform: scale(1);
    box-shadow: inset 0 0 10vw #000;
  }
}

main.psych-me-daddy {
  animation: psychedelic 10s ease-in-out infinite;
}

@keyframes psychedelic {
  0% {
    filter: hue-rotate(0deg) saturate(1) brightness(1) contrast(1) blur(0);
    transform: scale(1) translateX(0) rotate(0);
  }

  20% {
    transform: scale(1.01) translateX(1.1%) rotate(0.2deg);
  }

  33% {
    filter: hue-rotate(40deg) saturate(0.1) brightness(0.8) contrast(0.8)
      blur(1.1px);
  }

  50% {
    transform: scale(1.01) translateX(-2%) rotate(-0.2deg);
  }

  66% {
    filter: hue-rotate(-40deg) saturate(0.9) brightness(0.8) contrast(0.8)
      blur(1.2px);
    transform: scale(0.99) translateX(1%) rotate(-0.3deg);
  }

  80% {
    filter: hue-rotate(180deg) saturate(1.2) brightness(0.8) contrast(0.8)
      blur(1.2px);
    transform: scale(1) translateX(0) rotate(0.1deg);
  }

  100% {
    filter: hue-rotate(360deg) saturate(1) brightness(1) contrast(1) blur(0);
    transform: scale(1) translateX(0) rotate(0);
  }
}
